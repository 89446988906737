<template>
    <v-sheet style="position: relative;" color="background" class="fill-height">
        <reportsSidebar></reportsSidebar>
        <v-container class="h-100">
            <v-row class="align-center justify-center h-100">
                <v-col cols="12" md="6" lg="4">
                    <v-card elevation="11" color="panel">
                        <v-toolbar color="toolbar" tile flat dense>
                            <v-toolbar-title>Reports</v-toolbar-title>
                        </v-toolbar>
                        <v-divider color="white"></v-divider>
                        <v-card-text>
                            <p>Please use the navigation to enter a report</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
import reportsSidebar from '../../components/reports/sidebar';
export default {
    components: {
        reportsSidebar
    }
}
</script>