<template>
    <v-sheet style="position: relative;" color="background" class="fill-height">
        <reportsSidebar></reportsSidebar>
        <v-container class="h-100 d-flex w-100 align-stretch" fluid>
            <v-row class="align-stretch justify-center">
                <v-col cols="12">
                    <v-card elevation="11" color="panel" class="h-100 mb-0" :loading="loading">
                        <v-toolbar color="toolbar" tile flat dense class="remove-end-padding">
                            <v-toolbar-title>Marketing Report</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn icon @click="getPublishers" :loading="loading" :disabled="!$io.connected">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-divider color="white"></v-divider>
                        <v-stepper :value="step" class="elevation-0">
                            <v-stepper-header class="panel">
                                <v-stepper-step :complete="step > 1" step="1">Report Options</v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step :complete="step > 2" step="2">Report</v-stepper-step>
                            </v-stepper-header>
                            <v-divider color="white"></v-divider>
                            <v-stepper-items class="panel">
                                <v-stepper-content step="1" class="panel">
                                    <label class="caption mb-3 d-block">Publishers to Show:</label>
                                    <v-simple-table dense>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;" class="px-0">
                                                        <div class="d-flex w-100 justify-center align-center">
                                                            <v-checkbox class="mt-0 mb-1" hide-details v-bind="publisherSelectAllBind" readonly @click="handlePublishersSelectAllClick"></v-checkbox>
                                                        </div>
                                                    </th>
                                                    <th>Publisher</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(pub, i) in publishers" :key="i">
                                                    <td class="px-0">
                                                        <div class="d-flex w-100 justify-center align-center">
                                                            <v-switch class="mt-0 mb-1" v-model="shown" :value="pub.id" hide-details multiple></v-switch>
                                                        </div>
                                                    </td>
                                                    <td>{{pub.name}}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="generateReport" color="secondary" :disabled="0 == shown.length || !$io.connected" :loading="loading">
                                            Next
                                        </v-btn>
                                    </v-card-actions>
                                </v-stepper-content>
                                <v-stepper-content step="2" class="panel">
                                    <div style="overflow-x: auto; max-width: calc(100vw - 82px); width: 100%;">
                                        <v-simple-table dense v-if="null !== report">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th>&nbsp;</th>
                                                        <th>Spend</th>
                                                        <th>Leads</th>
                                                        <th>FTDs</th>
                                                        <th>CPL</th>
                                                        <th>CPA</th>
                                                        <th>CR</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-for="(cat, i) in report.items" :key="i">
                                                    <tr>
                                                        <th>{{cat.title}}</th>
                                                        <td>{{formatCurrency(cat.summary.spend)}}</td>
                                                        <td>{{formatInteger(cat.summary.leads)}}</td>
                                                        <td>{{formatInteger(cat.summary.ftds)}}</td>
                                                        <td>{{formatCurrency(cat.summary.cpl)}}</td>
                                                        <td>{{formatCurrency(cat.summary.cpa)}}</td>
                                                        <td>{{formatPercent(cat.summary.cr)}}</td>
                                                    </tr>
                                                    <tr v-for="(pub, pi) in cat.items" :key="['pub', pi].join('-')">
                                                        <td class="px-7">{{pub.title}}</td>
                                                        <td>{{formatCurrency(pub.summary.spend)}}</td>
                                                        <td>{{formatInteger(pub.summary.leads)}}</td>
                                                        <td>{{formatInteger(pub.summary.ftds)}}</td>
                                                        <td>{{formatCurrency(pub.summary.cpl)}}</td>
                                                        <td>{{formatCurrency(pub.summary.cpa)}}</td>
                                                        <td>{{formatPercent(pub.summary.cr)}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Grand Totals</th>
                                                        <th>{{formatCurrency(report.summary.spend)}}</th>
                                                        <th>{{formatInteger(report.summary.leads)}}</th>
                                                        <th>{{formatInteger(report.summary.ftds)}}</th>
                                                        <th>{{formatCurrency(report.summary.cpl)}}</th>
                                                        <th>{{formatCurrency(report.summary.cpa)}}</th>
                                                        <th>{{formatPercent(report.summary.cr)}}</th>
                                                    </tr>
                                                </tfoot>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="report = null" color="secondary" :loading="loading">
                                            Back
                                        </v-btn>
                                    </v-card-actions>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
import reportsSidebar from '../../components/reports/sidebar';
const numeral = require('numeral');
export default {
    components: {
        reportsSidebar
    },
    data: () => ({
        publishers: [],
        shown: [],
        report: null,
        intervals: {},
        loading: false,
    }),
    computed: {
        ignored() {
            return this.publishers.filter((v) => {
                return (this.shown.indexOf(v.id) == -1);
            })
        },
        ignoredIds() {
            return this.ignored.map((v) => {
                return v.id;
            })
        },
        step() {
            return (null == this.report) ? 1 : 2;
        },
        publisherSelectAllBind() {
            const ret = {
                indeterminate: false,
                'input-value': false,
            }
            if (this.loading) {
                ret['input-value'] = false;
            }
            else if (this.shown.length == this.publishers.length) {
                ret['input-value'] = true;
            }
            else if (this.shown.length > 0) {
                ret.indeterminate = true;
            }
            return ret;
        },
    },
    methods: {
        async getPublishers() {
            this.loading = true;
            try {
                const nbs = await this.$io.request('App/Models/User', 'getPublishers', [], 60000);
                this.publishers = nbs;
            }
            catch (error) {
                console.debug(error);
            }
            this.loading = false;
        },
        async generateReport() {
            this.loading = true;
            try {
                const nbs = await this.$io.request('App/Models/Report', 'marketing', [this.ignoredIds], 60000);
                this.report = nbs;
            }
            catch (error) {
                console.debug(error);
            }
            this.loading = false;
        },
        handlePublishersSelectAllClick() {
            if (this.shown.length > 0) {
                while (this.shown.length > 0) {
                    this.shown.splice(0, 1);
                }
            }
            else {
                for (let i = 0; i < this.publishers.length; i++) {
                    const publisher = this.publishers[i];
                    this.shown.push(publisher.id);
                }
            }
        },
        formatCurrency(value) {
            if (null == value) {
                return '∞';
            }
            return this.$currency.display(value);
        },
        formatInteger(value) {
            if (null == value) {
                return '∞';
            }
            const n = numeral(value)
            return n.format('0,0');
        },
        formatPercent(value) {
            if (null == value) {
                return '∞';
            }
            const n = numeral(value)
            return `${n.format('0,0.00')}%`;
        },
    },
    mounted() {
        this.intervals.publishers = setInterval(() => {
            this.getPublishers();
        }, (5 * 60 * 1000));
        this.getPublishers();
        this.$io.$on('connected', this.getPublishers);
    },
    beforeDestroy() {
        for (let i in this.intervals) {
            clearInterval(this.intervals[i]);
        }
        this.$io.$off('connected', this.getPublishers);
    },
}
</script>